import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

import { environment } from '@env/environment';

import { StoryService } from '@services/story/story.service';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShareComponent implements OnInit {

  color;
  colors = ['purple', 'mustard', 'teal', 'blue', 'lime', 'pink'];

  questions;

  slideConfig = {
    infinite: true,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1500,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  constructor(
    private router: Router,
    private storyService: StoryService,
    private titleService: Title,
    private metaService: Meta
  ) { }

  ngOnInit() {

    this.titleService.setTitle('Share my story | ' + environment.siteDescription + ' | ' + environment.siteTitle);

    this.storyService.getTerms('question').subscribe(
      questions => {
        this.questions = questions;

        this.questions.forEach(slide => {
          slide.color = this.colors[Math.floor(Math.random() * this.colors.length)];
        });
      }
    );

  }

  start(question) {
    this.storyService.activeQuestion = question;
    this.router.navigate(['write']);
  }

}
