import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AuthenticationService } from '@auth/authentication.service';
import { StoryService } from '@services/story/story.service';
import { UploadService } from '@services/upload/upload.service';

import { Story } from '@app/models/story';

@Component({
  selector: 'app-story',
  templateUrl: './story-onboard.component.html',
  styleUrls: ['./story-onboard.component.scss']
})
export class StoryOnboardComponent implements OnInit {

  stage;
  stages: any[];
  width;
  left;
  value;

  image;

  error;
  loading = {
    init: false,
    submit: false
  };
  complete = false;

  story: Story;
  autosave: Story;

  cameraActive = false;

  uploadResponse = { status: '', message: '', filePath: '' };

  @ViewChild('file', { static: false }) file: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private storyService: StoryService,
    private uploadService: UploadService
  ) {
    this.stages = [
      {
        n: 1,
        id: 'privacy',
        title: 'Begin',
      },
      {
          n: 2,
          id: 'write',
          title: 'Write',
      },
      {
        n: 3,
        id: 'image',
        title: 'Picture',
      },
      {
        n: 4,
        id: 'about',
        title: 'You',
      },
      {
        n: 5,
        id: 'review',
        title: 'Review',
      }
    ];
    this.stage = this.stages[0];

    if (this.getAutosave()) {
      this.autosave = this.getAutosave();
    } else {
      this.start();
    }

  }

  ngOnInit() {
    const unit = 100 / (this.stages.length * 2);
    this.width = unit * (this.stages.length * 2 - 2) + '%';
    this.left = unit + '%';
    this.value = 2;
  }

  start() {
    localStorage.removeItem('stl-autosave');
    this.story = new Story('1', this.storyService.activeQuestion);
  }

  restore() {
    if (localStorage.getItem('stl-autosave')) {
      this.story = JSON.parse(localStorage.getItem('stl-autosave'));
    }
  }

  autosaveExists() {
    if (localStorage.getItem('stl-autosave')) {
      return true;
    } else {
      return false;
    }
  }

  open(e) {
    e.stopPropagation();
    this.file.nativeElement.click();
  }

  getFile(event) {

    const resizeImage = (base64Str, maxWidth = 400, maxHeight = 350) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = base64Str;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const MAX_WIDTH = maxWidth;
          const MAX_HEIGHT = maxHeight;
          let width = img.width;
          let height = img.height;
          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          resolve(canvas.toDataURL());
        };
      });
    };

    if (event.target.files.length > 0) {

      const file = event.target.files[0];
      this.story.file = file;
      // console.log(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {

        resizeImage(reader.result, 800, 800).then((image) => {
          this.image = image;
          this.story.image = image;
          this.cameraActive = true;
        });

      };

      reader.onerror = (error) => {
        // console.log('Error: ', error);
      };
    }

  }

  getAutosave() {
    return JSON.parse(localStorage.getItem('stl-autosave'));
  }

  navigate(direction) {
    switch (direction) {
      case 'prev':
        this.stage = this.stages[this.stages.indexOf(this.stage) - 1];
        break;
      case 'next':
        this.stage = this.stages[this.stages.indexOf(this.stage) + 1];
        break;
    }
    this.setStage(this.stage.id);
  }

  setStage(name: string) {
    this.stage = this.stages.find(s => s.id === name);
    this.value = (this.stage.n - 1) / (this.stages.length - 1) * 100;
    if (this.value <=  0) {
      this.value = 2;
    }
  }

  setContent(content) {
    this.story.content = content;
    this.save();
  }

  setImage(image) {
    this.story.image = image;
    this.cameraActive = false;
    this.image = null;
    this.save();
  }

  getStory() {
    return this.story;
  }

  save() {
    this.story.lastUpdated = new Date();
    localStorage.setItem('stl-autosave', JSON.stringify(this.getStory()));
    // console.log('saved', this.story); // localStorage.getItem('stl-autosave')
  }

  string_to_slug (str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
    // remove accents, swap ñ for n, etc
    const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
    const to   = 'aaaaeeeeiiiioooouuuunc------';
    for (let i = 0, l = from.length ; i < l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

  performCreateStory(story) {

    // console.log(payload);
    // console.log(story);

    const formData: FormData = new FormData();

    const imageMeta = story.image.split(',');
    const mimeString = imageMeta[0].split(':')[1].split(';')[0];

    fetch(this.story.image)
      .then(res => res.blob())
      .then(blob => {

        const file = new File([blob], this.string_to_slug(story.title) + '.png', { type: mimeString });
        formData.append('file', file);

        // upload the image
        this.uploadService.upload(formData).subscribe(
          res => {

            // console.log(res);

            switch (res.status) {
              case 'progress':
                // console.log(res);
                break;
              case 'unhandled':
                break;
              case 'complete':

                // get image ID
                story.image = res.response.id;

                // console.log('CREATING', story);

                // create story
                this.storyService.create(story).subscribe(
                  response => {
                    // console.log(response);
                    this.complete = true;
                    localStorage.removeItem('stl-autosave');
                  }
                );
                break;
            }

          },
          err => (this.error = err)
        );

      });

  }

  createStory() {

    const today = new Date();
    const date = ('0' + today.getDate()).slice(-2);
    const month = ('0' + (today.getMonth() + 1)).slice(-2);
    const year = today.getFullYear();

    this.story.title = this.story.name + ' - ' + date + '/' + month + '/' + year,

    this.loading.submit = true;

    if (this.authenticationService.isLoggedIn()) {

      this.performCreateStory(this.story);

    } else {

      this.authenticationService.login()
      .subscribe(
          data => {

            this.performCreateStory(this.story);

          },
          error => {
            this.error = error.error;
            this.loading.submit = false;
          }
        );

    }

  }

  cancel() {
    this.cameraActive = false;
  }
}
