import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

declare let gtag: (...args: any[]) => void;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'storiesthatlift';

  constructor(public router: Router) {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {

        // console.log(event.urlAfterRedirects);
        gtag('config', 'UA-172745931-1', {page_path: event.urlAfterRedirects});
      }
    });
  }
}
