import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

import { StoryService } from '@app/services/story/story.service';

@Component({
  selector: 'app-stories',
  templateUrl: './stories.component.html',
  styleUrls: ['./stories.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(500, style({opacity: 1}))
      ])
    ])
  ]
})
export class StoriesComponent implements OnInit {

  @Input() categories: [];
  @Input() count: number;
  @Input() carousel = false;
  @Input() columns = 3;
  @Input() contentLayout;

  @Input()
  set filter(val: any) {
    this._filter = val;
    this.active = true;
    this.load();
  }

  @Output() loaded = new EventEmitter();

  private _filter;
  active = false;

  stories = [];

  loading;
  full;

  slideConfig = {
    infinite: true,
    dots: false,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 1000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  constructor(
    private storiesService: StoryService
  ) {  }

  ngOnInit(): void {

    if (this.columns > 1) {
      this.full = true;
    } else {
      this.full = false;
    }

  }

  load() {
    this.loading = true;
    this.storiesService.getStories({
      filter: this._filter
    }).subscribe(
      res => {
        this.loaded.emit(true);
        this.loading = false;
        this.stories = res.stories;
        this.stories.forEach(story => {
          story.post_date = new Date(story.post_date).toISOString();
        });
      }
    );
  }

}
