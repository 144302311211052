<div class="story-card position-relative bg-{{ color }} text-white py-3 py-md-4 px-4 px-md-5 rounded h-100 cursor" [routerLink]="['/story', story.post_name]">
  <div class="date position-absolute">
    <strong>
      <span class="day">{{ story.post_date | toDateObj | date:'dd' }}</span><br />
      <span class="month">{{ story.post_date | toDateObj | date:'LLL' }}</span>
    </strong>
  </div>
  <div class="content pt-5 pt-md-5 pb-5">

    <img *ngIf="imageFirst" [src]="story.image" class="rounded-circle mt-2 mt-md-3 mb-4" [attr.alt]="'Picture of ' + story.post_title" />

      <div class="meta"><ng-container *ngFor="let category of story.categories; let last = last;">
        {{ category.name }}<span *ngIf="!last"> | </span></ng-container>
      </div>
      <h2 class="mb-md-3 mb-4">{{ story.post_excerpt }}</h2>

      <strong [innerHtml]="story.post_title"></strong><br />

    <img *ngIf="!imageFirst" [src]="story.image" class="rounded-circle mt-4 mb-4" [attr.alt]="'Picture of ' + story.post_title" />

    <a [routerLink]="['/story', story.post_name]" class="btn btn-link text-white" aria-label="Read their full story"><fa-icon [icon]="['far', 'eye']" class="mr-2 text-white"></fa-icon><strong>View story</strong></a>

  </div>
</div>
