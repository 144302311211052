<div class="filter pb-5 container-md">

  <div class="input-group pb-4">
    <input type="text" [ngClass]="['bg-' + color + '-light']" [(ngModel)]="searchValue" (ngModelChange)="this.searchValueUpdate.next($event)" [placeholder]="placeholder" class="form-control form-control-lg rounded border-0" aria-label="Filter">
    <fa-icon [icon]="['far', 'search']" id="icon-search" class="position-absolute" [class.text-gray-light]="!color" [ngClass]="['text-' + color]"></fa-icon>
    <fa-icon *ngIf="searchValue && !_loading" [icon]="['fal', 'times']" id="icon-clear" class="position-absolute cursor" [class.text-gray-light]="!color" [ngClass]="['text-' + color]" (click)="reset()"></fa-icon>
    <button class="btn btn-link text-gray-light d-none" type="button" aria-haspopup="true" aria-expanded="false"></button>
    <mat-progress-spinner *ngIf="_loading" [diameter]="30" [class.stroke-primary]="!color" [ngClass]="['stroke-' + color]" class="mt-1 ml-2 position-absolute" mode="indeterminate"></mat-progress-spinner>
  </div>
  
  <div *ngIf="categories" class="row" [class.opened]="visible" [class.closed]="!visible">
    <div class="col-12 col-md-4">
      <div class="position-relative">
        <fa-icon [icon]="['far', 'flag-alt']" class="filter icon-sm d-none d-md-block" [class.text-primary]="!color" [class.text-white]="color"></fa-icon>
        <span class="meta d-none d-md-block">Topic</span>
          <ng-select
            class="w-100 mb-2"
            [(ngModel)]="filter.themes"
            (ngModelChange)="applyFilter()"
            name="area"
            [items]="themes"
            [multiple]="true"
            bindValue="term_id"
            bindLabel="name"
            [searchable]="false"
            [placeholder]="'Select topics...'">
          </ng-select>
      </div>
      
    </div>
    <div class="col-12 col-md-4">
      <div class="position-relative">
        <fa-icon [icon]="['far', 'briefcase']" class="filter icon-sm d-none d-md-block" [class.text-primary]="!color" [class.text-white]="color"></fa-icon>
        <span class="meta d-none d-md-block">Area</span>
          <ng-select
            class="w-100 mb-2"
            [(ngModel)]="filter.areas"
            (ngModelChange)="applyFilter()"
            name="area"
            [items]="areas"
            [multiple]="true"
            bindValue="term_id"
            bindLabel="name"
            [searchable]="false"
            [placeholder]="'Select areas...'">
          </ng-select>
      </div>
      
    </div>
    <div class="col-12 col-md-4">
      <div class="position-relative">
        <fa-icon [icon]="['fal', 'map-marker-alt']" class="filter d-none d-md-block" [class.text-primary]="!color" [class.text-white]="color"></fa-icon>
        <span class="meta d-none d-md-block">Location</span>
          <ng-select
            class="w-100 mb-2"
            [(ngModel)]="filter.locations"
            (ngModelChange)="applyFilter()"
            name="area"
            [items]="locations"
            [multiple]="true"
            bindValue="term_id"
            bindLabel="name"
            [searchable]="false"
            [placeholder]="'Select locations...'">
          </ng-select>
      </div>
      
    </div>
  </div>

  <!-- <button class="btn btn-sm btn-primary mt-3 text-uppercase" (click)="applyFilter()">Apply Filter</button> -->

  <!-- <pre>{{ filter | json }}</pre> -->

</div>