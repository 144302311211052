import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-story-card',
  templateUrl: './story-card.component.html',
  styleUrls: ['./story-card.component.scss']
})
export class StoryCardComponent implements OnInit {

  @Input() story;
  @Input() imageFirst;

  color = 'purple';
  // colors = ['purple', 'mustard', 'teal', 'blue', 'lime', 'pink'];

  constructor() { }

  ngOnInit() {
    if (this.story.categories) {
      this.color = this.story.categories[0].color;
    }
     //this.colors[Math.floor(Math.random() * this.colors.length)];
  }

}
