<mat-progress-spinner *ngIf="loading" [diameter]="60" class="success mt-5 _ml-2 m-auto" mode="indeterminate"></mat-progress-spinner>

<ng-container *ngIf="stories">

  <div class="row" *ngIf="!loading && !carousel">
    <div @fade 
      [ngClass]="full ? 'col-12' : 'col-12 col-lg-6 col-xl-4'"
      class="w-100 pb-5"
      *ngFor="let story of stories; let even=even;">
        <app-story-card [story]="story" [imageFirst]="even"></app-story-card>
    </div>
    
  </div>

  <ngx-slick-carousel @fade *ngIf="!loading && carousel" class="carousel" 
    #slickModal="slick-carousel" 
    [config]="slideConfig">
    <div ngxSlickItem *ngFor="let story of stories; let even=even;" class="slide h-100 p-3">
      <app-story-card [story]="story" [imageFirst]="even"></app-story-card>
    </div>
  </ngx-slick-carousel>

</ng-container>

<div class="container-md" *ngIf="active && !loading && stories.length < 1">Sorry, we can't find any stories matching your selection</div>