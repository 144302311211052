import { Injectable } from '@angular/core';
import { Adapter } from '@helpers/adapter';

export class Story {
    id: number;
    question: any;
    name: string;
    email: string;
    title: string;
    date: string;
    about: [];
    location: [];
    areas: [];
    acceptedPrivacy: boolean;
    content: string;
    image: any;
    file: any;
    lastUpdated: any;

    constructor(
        id?,
        question?,
        name?,
        email?,
        title?,
        date?,
        about?,
        location?,
        areas?,
        acceptedPrivacy?,
        content?,
        image?,
        file?,
        lastUpdated?
    ) {
        this.id = id;
        this.question = question;
        this.name = name;
        this.email = email;
        this.title = title;
        this.date = date;
        this.about = about;
        this.location = location;
        this.areas = areas;
        this.acceptedPrivacy = false;
        this.content = '';
        this.image = image;
        this.file = file;
        this.lastUpdated = lastUpdated;
    }
}

@Injectable({
    providedIn: 'root'
})
export class StoryAdapter implements Adapter<Story> {

  adapt(item: any): Story {
    return new Story(
        item.ID,
        item.custom_fields.activityType,
        item.custom_fields.activityDate,
        item.custom_fields.exerciseName,
    );
    }
}
