import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StoryService } from '@services/story/story.service';

@Component({
  selector: 'app-story-user-form',
  templateUrl: './story-user-form.component.html',
  styleUrls: ['./story-user-form.component.scss']
})
export class StoryUserFormComponent implements OnInit {

  @Input() story;
  @Output() autosave = new EventEmitter();
  @Output() continue = new EventEmitter();
  @Output() back = new EventEmitter();

  form: FormGroup;
  // user;

  topics;
  locations;
  areas;

  submitted;
  loading = {
    init: false
  };

  constructor(
    private formBuilder: FormBuilder,
    private storyService: StoryService,
  ) { }

  ngOnInit(): void {

    this.storyService.getTerms('theme').subscribe(
      topics => {
        this.topics = topics;
      }
    );

    this.storyService.getTerms('location').subscribe(
      locations => {
        this.locations = locations;
      }
    );

    this.storyService.getTerms('nature-of-work').subscribe(
      areas => {
        this.areas = areas;
      }
    );

    this.form = this.formBuilder.group({
      id: [this.story.id],
      name: [this.story.name, Validators.required],
      email: [this.story.email, Validators.required],
      about: [this.story.about, Validators.required],
      location: [this.story.location, Validators.required],
      area: [this.story.area, Validators.required]
    });

  }

  proceed() {
    this.continue.emit(this.form.value);
  }

}
