import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '@auth/authentication.service';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private router: Router
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // add authorization header with jwt token if available
        const user = this.authenticationService.currentUserValue;

        if (user && user.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${user.token}`
                }
            });
        }

        // return next.handle(request).pipe( tap(() => {},
        //     (err: any) => {
        //     if (err instanceof HttpErrorResponse) {
        //         if (err.status !== 401) {
        //         return;
        //         }
        //         this.router.navigate(['login']);
        //     }
        // }));

        return next.handle(request);
    }
}
