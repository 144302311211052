<header [ngClass]="bg ? 'bg-' + bg : ''">
  <div class="container-md">
    <a routerLink="/home" aria-label="Go back to the home page">
      <div *ngIf="bg" aria-label="Leading to Change logo" class="logo" [inlineSVG]="'assets/images/ltc-logo-white.svg'">
      </div>
      <div *ngIf="!bg" aria-label="Leading to Change logo" class="logo"
        [inlineSVG]="'assets/images/ltc-logo-purple.svg'"></div>
    </a>
    <div class="float-right">
      <a routerLink="/share" class="btn btn-link" [class.text-white]="bg">
        <fa-icon [icon]="['far', 'comment']" class="mr-2" aria-label="Share your story"></fa-icon><strong>Share my
          story</strong>
      </a>
    </div>
  </div>
</header>