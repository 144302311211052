<app-header></app-header>

<div class="container-sm" class="py-4 py-md-5">

  <div class="container-xs text-center">
    <h1>Get in touch</h1>
    <p>We love to hear from you, use the details below to send us comments, suggestions or any queries and one of team will get back to you as soon as we can. Thanks!</p>
    <p *ngIf="complete">
        <strong>Thanks for getting in touch. We'll get back to you soon.</strong>
    </p>
  </div>

  <div class="container-xs">

    <form *ngIf="!complete" name="form" [formGroup]="form" #f class="my-5">

    <div class="form-group position-relative">
      <input required formControlName="firstname" class="form-control" placeholder="First name (required)"
      [class.is-invalid]="form.controls['firstname'].touched && form.controls['firstname'].errors"
      [class.is-valid]="form.controls['firstname'].touched && !form.controls['firstname'].errors" />
    </div>

    <div class="form-group position-relative">
      <input formControlName="lastname" class="form-control" placeholder="Last name" />
      
    </div>

    <div class="form-group position-relative">
      <input required formControlName="email" class="form-control" placeholder="Your email address (required)"
      [class.is-invalid]="form.controls['email'].touched && form.controls['email'].errors"
      [class.is-valid]="form.controls['email'].touched && !form.controls['email'].errors" />
    </div>

    <div class="form-group">
      <textarea required formControlName="message" class="form-control" rows="5" placeholder="How can we help? (required)"
      [class.is-invalid]="form.controls['message'].touched && form.controls['message'].errors"
      [class.is-valid]="form.controls['message'].touched && !form.controls['message'].errors"></textarea>
    </div>

    <div class="clearfix">
      <button class="btn btn-primary float-left" [disabled]="!form.valid" (click)="submit(form.value)" aria-label="Submit contact form">
        Submit
      </button>

      <mat-progress-spinner *ngIf="loading" [color]="'accent'" [diameter]="32" class="mt-1 ml-3 float-left" mode="indeterminate"></mat-progress-spinner>
      <small *ngIf="form.touched && !form.valid" class="text-danger float-right">Please fill in all the required fields</small>
    </div>

  </form>

  </div>

</div>

<app-footer></app-footer>