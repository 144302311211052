
<app-page-loader *ngIf="!content"></app-page-loader>

<div @fade *ngIf="content">

  <app-header></app-header>

  <div *ngIf="content" class="container-md py-4 py-md-5">

    <h1 [innerHtml]="content.title.rendered"></h1>

    <div [innerHtml]="content.content.rendered"></div>

  </div>

  <app-footer></app-footer>

</div>