<app-header></app-header>

<div class="container-md">

  <div *ngIf="!complete" class="mt-4 mt-md-5">
    <div class="mx-auto option-container">
      <mat-progress-bar class="bar" mode="determinate" [style.width]="width" [style.left]="left" [value]="value" [color]="'accent'"></mat-progress-bar>
      <div class="row _no-gutters">
        <div *ngFor="let s of stages; let i = index;" class="col">
          <p class="mb-0 _cursor small table-control-text text-uppercase">
            <span class="d-block d-sm-none">{{ i + 1 }}</span>
            <span class="d-none d-sm-block">{{ s.title }}</span>
          </p> <!-- (click)="setStage(s.id)" -->
          <div class="bg-primary"></div>
        </div>
      </div>
    </div>
   
  </div>

  <div class="container-sm text-center mb-3 mb-sm-0">

    <ng-container *ngIf="!story && autosaveExists()">
      <h1>Before you start...</h1>
      <p>It looks as though you started one already which you last updated on {{ autosave.lastUpdated | date:'medium' }}</p>
      <div class="text-left">
        <div class="alert alert-primary mt-2 my-4" role="alert">
          <div [innerHtml]="autosave.content" style="padding: 30px 40px;" class="px-0 py-3 px-sm-3 py-sm-5">

          </div>
          <!-- <pre>{{ autosave | json }}</pre> -->
        </div>
      </div>
      <div>
        <button class="btn btn-primary mr-2" (click)="restore()" aria-label="Restore the story you'd started last time">Restore</button>
        <button class="btn btn-secondary" (click)="start()" aria-label="Discard and start again">Discard and start again</button>
      </div>
    </ng-container>

    <ng-container *ngIf="story">

    <div *ngIf="stage.id==='about'">
      <h2>Help others find your story</h2>

      <p>Your answers help make it easier for people to find your story and others like it. We will keep your information <a routerLink="/privacy">private</a>.</p>

      <app-story-user-form [story]="story" (autosave)="save()" (continue)="navigate('next')" (back)="navigate('prev')"></app-story-user-form>

    </div>

    <div *ngIf="stage.id==='privacy'">

      <h2>Just before we begin...</h2>
      
      <div class="bg-white text-left p-4 p-md-5 my-4 my-md-5">
        <p>Here at Project Lift it's important that we treat everyone with care and respect and that includes both your story and your personal information. Before we go any further, we'd like to put your mind at rest by sharing how we collect, store and share the information you give us so that you have confidence that we are valuing you and your story. Please review our full <a routerLink="/privacy">privacy policy</a> and also our <a routerLink="/terms-and-conditions">terms and conditions</a> before clicking the box below to let us know you are ok with all of this.</p>
        <div>
          <fa-icon class="float-left" [icon]="['fal', 'check-square']" *ngIf="story.acceptedPrivacy" (click)="story.acceptedPrivacy = !story.acceptedPrivacy" aria-label="I don't accept the terms and conditions"></fa-icon>
          <fa-icon class="float-left" [icon]="['fal', 'square']" *ngIf="!story.acceptedPrivacy" (click)="story.acceptedPrivacy = !story.acceptedPrivacy" aria-label="I accept the terms and conditions"></fa-icon>
          <div id="accept-terms">
            <strong>I believe you will look after my data and I am happy with the terms and conditions</strong>
          </div>
        </div>

      </div>

      <div class="navigation clearfix">
        <button class="btn btn-link float-right" [disabled]="!story.acceptedPrivacy" (click)="navigate('next')" aria-label="Next: write your story">
          <fa-icon [icon]="['fal', 'arrow-circle-right']"></fa-icon>
        </button>
      </div>

    </div>

    <div *ngIf="stage.id=='write'">

      <app-story-content [contentValue]="story.content" [question]="story.question" (update)="setContent($event)" class="clearfix"></app-story-content>

      <div class="navigation clearfix">
        <button class="btn btn-link float-left" (click)="navigate('prev')" aria-label="Back: accept terms and conditions">
          <fa-icon [icon]="['fal', 'arrow-circle-left']"></fa-icon>
        </button>
        <button class="btn btn-link float-right" (click)="navigate('next')" aria-label="Next: choose an picture">
          <fa-icon [icon]="['fal', 'arrow-circle-right']"></fa-icon>
        </button>
      </div>

    </div>

    <div *ngIf="stage.id=='image'">

      <h2>Add a picture</h2>

      <img id="image">

      <div class="mt-sm-5 mt-2">

        <div *ngIf="story.image && !cameraActive" class="text-center">
          <img class="w-75 rounded-circle mb-5" [src]="story.image" />
          <p><button class="btn btn-primary" (click)="story.image=null; image=null;" aria-label="Select a different image">Change</button></p>
        </div>

        <div *ngIf="!cameraActive && !story.image" id="image-source-selector">
          <div class="container-lg">
            <div class="row no-gutters d-flex flex-sm-row flex-column">
                <div class="col-12 col-sm-6 mb-3 mb-sm-0" (click)="cameraActive=true" aria-label="Use your camera to take a photo">
                  <div class="image-option rounded px-3 mr-md-2">
                      <fa-icon [icon]="['fal', 'camera']" class="text-primary"></fa-icon>
                      <p><strong class="text-center text-uppercase">Use camera</strong></p>
                    </div>
                  </div> 
                  <div class="col-12 col-sm-6">
                    <div class="image-option rounded px-3 ml-md-2">
                    <label for="file" onclick="" style="cursor: pointer;" aria-label="Browse your device to select an image">
                      <fa-icon [icon]="['fal', 'upload']" class="text-primary"></fa-icon>
                      <p><strong class="text-uppercase">Browse images</strong></p>
                      <input id="file" type="file" accept="image/*" (change)="getFile($event)" #file class="d-none" />
                      <strong *ngIf="uploadResponse.status === 'progress'" class="text-white">{{ uploadResponse.message }}%</strong>
                    </label>
                  </div>
                  </div>
            </div>
          </div>
        </div>

        <app-image-capture
        *ngIf="cameraActive"
        [imageUpload]="image"
        (setImage)="setImage($event, story)"
        (cancel)="cancel()"></app-image-capture>

      </div>

      <div class="navigation clearfix">
        <button class="btn btn-link float-left" (click)="navigate('prev')" aria-label="Back: write your story">
          <fa-icon [icon]="['fal', 'arrow-circle-left']"></fa-icon>
        </button>
        <button class="btn btn-link float-right" [disabled]="!story.image"  (click)="navigate('next')" aria-label="Next: tell us a bit about what you and your story is about to help people find it">
          <fa-icon [icon]="['fal', 'arrow-circle-right']"></fa-icon>
        </button>
      </div>

    </div>

    <div *ngIf="stage.id=='review'">

      <ng-container *ngIf="!complete">

        <img class="rounded-circle mb-5 box-shadow" [src]="story.image" style="width: 170px;" aria-label="A preview of the image you just selected or took" />

        <div [innerHtml]="story.content" class="text-left"></div>

        <div class="navigation mt-5 clearfix">
          <button class="btn btn-link float-left" (click)="navigate('prev')" aria-label="Back: tell us a bit about what you and your story is about to help people find it">
            <fa-icon [icon]="['fal', 'arrow-circle-left']"></fa-icon>
          </button>
          <button class="btn btn-lg btn-primary mt-4" (click)="createStory()" aria-label="Submit story for review">Submit my story for review</button>
        </div>
      </ng-container>

      <ng-container *ngIf="complete" class="pt-5">
        <h2>Thanks</h2>
        <p>Your story has uploaded successfully! We value you taking the time to share your story with us. All stories undergo our review process and a selection will be uploaded to the site regularly. One of our team will be in touch shortly to chat through next steps.</p> 
        <p>If you have any queries or concerns, <a routerLink="/contact" aria-label="Navigate to contact us">please get in touch.</a></p>
      </ng-container>

      

    </div>

  </ng-container>

  </div>

</div>

<app-footer></app-footer>