import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import * as BalloonEditor from '@ckeditor/ckeditor5-build-balloon';
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { Story } from '@models/story';

@Component({
  selector: 'app-story-content',
  templateUrl: './story-content.component.html',
  styleUrls: ['./story-content.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StoryContentComponent implements OnInit {

  @Input() contentValue;
  @Input() question;
  @Output() update = new EventEmitter();

  loading;

  editor = BalloonEditor;

  options = {
      placeholder: 'Tell us about it...',
      toolbar: [ 'heading', '|', 'bold', 'italic' ],
      heading: {
          options: [
              { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
              { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
              { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
          ]
      }
  };

  contentValueUpdate = new Subject<string>();

  constructor() { }

  ngOnInit(): void {
    this.contentValueUpdate
    .pipe(debounceTime(350), distinctUntilChanged())
    .subscribe(value => {
      this.loading = true;
      timer(400).subscribe(x => { this.loading = false; });
      this.update.emit(value);
      // this.story.content = value;
    });
  }

}
