import { Component, Input, Output, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { trigger, transition, animate, style } from '@angular/animations';

import { StoryService } from '@services/story/story.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(-100%)'}),
        animate('200ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateY(-100%)'}))
      ])
    ])
  ]
})
export class SearchComponent implements OnInit {

  @Input() placeholder = 'Explore stories...';
  @Input() color: string;
  @Input() categories: boolean;

  @Input()
  set loading(val: any) {
    this._loading = val;
  }

  @Output() update = new EventEmitter();
  @Output() clear = new EventEmitter();

  _loading = false;

  searchValue: string;
  searchValueUpdate = new Subject<string>();

  themes;
  locations;
  areas;

  filter = {
    term: '',
    themes: '',
    areas: '',
    locations: '',
  };

  visible = true;

  constructor(
    private storyService: StoryService,
  ) {
    this.searchValueUpdate
      .pipe(debounceTime(350), distinctUntilChanged())
      .subscribe(value => {
        this.filter.term = value;
        this.update.emit(this.filter);
      });
  }

  ngOnInit() {
    this.storyService.getTerms('theme').subscribe(
      themes => {
        this.themes = themes;
      }
    );

    this.storyService.getTerms('location').subscribe(
      locations => {
        this.locations = locations;
      }
    );

    this.storyService.getTerms('nature-of-work').subscribe(
      areas => {
        this.areas = areas;
      }
    );
  }

  applyFilter() {
    this.update.emit(this.filter);
  }

  reset() {
    this.searchValue = null;
    this.clear.emit();
  }

}
