import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  constructor(private httpClient: HttpClient) {}

  public upload(data) {
    
    for (const pair of data.entries()) {
      // console.log(pair[0]+ ', ' + pair[1]);
    }

    return this.httpClient
      .post<any>(environment.apiUrl + '/media', data, {
        reportProgress: true,
        observe: 'events'
      })
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round((100 * event.loaded) / event.total);
              return { status: 'progress', message: progress };

            case HttpEventType.Response:
              return { status: 'complete', response: event.body };
            default:
              return { status: 'unhandled', type: event.type };
          }
        })
      );
  }

}
