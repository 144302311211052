import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from '@pages/home/home.component';
import { StoryComponent } from '@pages/story/story.component';
import { ArchiveComponent } from '@pages/archive/archive.component';
import { StoryOnboardComponent } from '@components/story-onboard/story-onboard.component';
import { ShareComponent } from '@pages/share/share.component';
// import { AboutComponent } from '@pages/about/about.component';
import { ContactComponent } from '@pages/contact/contact.component';
// import { PrivacyComponent } from '@pages/privacy/privacy.component';
// import { TermsAndConditionsComponent } from '@pages/terms-and-conditions/terms-and-conditions.component';

import { PageContentComponent } from '@components/page-content/page-content.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'story/:slug',
    component: StoryComponent,
  },
  {
    path: 'stories',
    component: ArchiveComponent,
  },
  {
    path: 'share',
    component: ShareComponent,
  },
  {
    path: 'write',
    component: StoryOnboardComponent,
  },
  {
    path: 'about',
    component: PageContentComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'privacy',
    component: PageContentComponent,
  },
  {
    path: 'terms-and-conditions',
    component: PageContentComponent,
  },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
