<app-header></app-header>

<div class="py-4 py-md-5">

  <div class="container-sm text-center">

    <h1>Join the conversation</h1>

    <p class="mt-4 mt-md-5">SStories That Inspire Change offers a trusted space for people working for the NHS to be
      themselves. We seek to encourage conversation about the vulnerable moments and real experiences of NHS staff all
      over Scotland. Through sharing our joys, frustrations, triumphs and fears, we hope to create a community where
      compassion and hope can flourish. Share your story and our editing team will review your submission before getting
      in touch with next steps.</p>

    <div routerLink="/write" class="cursor mt-5" aria-label="Share your story">
      <fa-icon [icon]="['far', 'comment']" class="h3"></fa-icon>
      <p class="text-uppercase">Share your story</p>
    </div>

  </div>

  <div class="container-lg pt-3 pt-md-5 text-center">

    <ngx-slick-carousel *ngIf="questions" class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
      <div ngxSlickItem *ngFor="let question of questions" class="slide h-100">
        <div [ngClass]="'text-' + question.color" class="cursor h-100 bg-white mx-3 px-4 py-5" (click)="start(question)"
          [attr.aria-label]="'Share your story by beginning with the question: ' + question.name">
          <h4 class="mb-4">{{ question.name }}</h4>
          <fa-icon [icon]="['far', 'comment']" class="h3"></fa-icon>
        </div>
      </div>
    </ngx-slick-carousel>

    <a class="cursor text-decoration-none my-4 btn btn-link" routerLink="/stories" aria-label="Go back to all stories">
      <fa-icon class="h1" [icon]="['fal', 'arrow-circle-left']"></fa-icon><br />
      <span class="text-uppercase">Return to all stories</span>
    </a>

  </div>

</div>

<app-footer></app-footer>