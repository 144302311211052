<app-header></app-header>

<div id="content-container" class="py-4 py-md-5">

  <app-search [categories]="true" [loading]="loading.search" (update)="search($event)" (clear)="filter=null">
  </app-search>

  <div *ngIf="filter" class="container-xl">
    <app-stories [columns]="1" [filter]="filter" (loaded)="loaded($event)"></app-stories>
  </div>

  <ng-container *ngIf="!filter">

    <div class="container-sm text-center pb-4 pb-md-5">
      <p>Stories That Inspire Change offers a trusted space for people working anywhere in or with Health, Social Work
        and Social Care to be themselves. We seek to encourage conversation about the celebrations, vulnerable moments
        and real
        experiences of real people all over Scotland. Through sharing our joys, frustrations, triumphs and fears, we
        hope to create a community where compassion and hope can flourish. Share your story and our team will be in
        touch with next steps.</p>
      <p>Share your story and our editing team will review your submission before getting in touch with next steps.</p>
    </div>

    <div class="container-xl">

      <app-stories [carousel]="true" [columns]="1" [filter]="filter"></app-stories>

    </div>

    <div class="text-center">
      <a class="cursor text-decoration-none my-4 my-md-5 btn btn-link" routerLink="/stories"
        aria-label="Browse all stories">
        <fa-icon class="h1" [icon]="['fal', 'arrow-circle-right']"></fa-icon><br />
        <span class="text-uppercase">View all stories</span>
      </a>
    </div>

    <h2 class="pt-5 pb-4 pb-md-5 px-4 text-center">Join the conversation <strong><br /><a routerLink="/share"
          aria-label="Share you story">Share your story</a></strong></h2>

  </ng-container>

</div>


<app-footer></app-footer>