import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
  ElementRef,
  ViewChild,
  AfterContentInit,
  Input,
  AfterViewInit,
} from "@angular/core";

import { Subject, Observable } from "rxjs";
import { WebcamImage, WebcamInitError, WebcamUtil } from "ngx-webcam";

import { ImageCroppedEvent } from "ngx-image-cropper";

@Component({
  selector: "app-image-capture",
  templateUrl: "./image-capture.component.html",
  styleUrls: ["./image-capture.component.scss"],
})
export class ImageCaptureComponent implements OnInit, AfterViewInit {
  @Input() imageUpload;
  @Output() setImage = new EventEmitter();
  @Output() cancel = new EventEmitter();

  type;
  image;
  imageChangedEvent: any = "";
  croppedImage: any = "";
  croppedImages = [];

  // toggle webcam on/off
  public output = [];
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
    width: { ideal: 1024 },
    height: { ideal: 576 },
  };
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage: WebcamImage = null;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<
    boolean | string
  >();

  private element: any;

  private width: number;
  // private height: number;

  @ViewChild("imageWrapper", { static: false }) elementView: ElementRef;

  @HostListener("window:resize", ["$event"])
  onResize(event?: Event) {
    this.width = this.element.offsetWidth;
  }

  constructor() {

  }

  ngOnInit() {
    WebcamUtil.getAvailableVideoInputs().then(
      (mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      }
    );

    if (this.imageUpload) {
      this.image = this.imageUpload;
    }
  }

  ngAfterViewInit() {
    this.element = this.elementView.nativeElement;
    // console.log("Element width: " + this.element.offsetWidth) //The width here is always equal to 0
    this.onResize();
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.output = [];
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    // console.info('received webcam image', webcamImage);
    this.output.push(webcamImage);
    this.webcamImage = webcamImage;
    this.image = webcamImage.imageAsDataUrl;
    // console.log(this.image);
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  reset() {
    this.image = null;
    this.croppedImages = [];
  }

  crop() {
    this.croppedImages.push(this.croppedImage);
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }
}
