import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { trigger, style, animate, transition } from '@angular/animations';

import { Router } from '@angular/router';

import { PageService } from '@services/page/page.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-page-content',
  templateUrl: './page-content.component.html',
  styleUrls: ['./page-content.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(200, style({opacity: 1}))
      ])
    ])
  ]
})
export class PageContentComponent implements OnInit {

  content;

  constructor(
    private router: Router,
    private pageService: PageService,
    private titleService: Title,
    private metaService: Meta
  ) { }

  ngOnInit() {
    this.pageService.getBySlug(this.router.url).subscribe(
      content => {
        this.content = content[0];
        this.titleService.setTitle(this.content.title.rendered + ' | ' + environment.siteDescription + ' | ' + environment.siteTitle);
      }
    );
  }

}
