import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class PageService {

  constructor(
    private http: HttpClient
  ) { }

  getBySlug(slug): Observable<any> {
    return this.http.get<any[]>(environment.apiUrl + '/pages?slug=' + slug);
  }
}
