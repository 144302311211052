import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;

    constructor(
        private http: HttpClient,
        private router: Router,
    ) {
        this.currentUserSubject = new BehaviorSubject<any>({}); // JSON.parse(localStorage.getItem('user'))
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): any {
      return this.currentUserSubject.value;
    }

    login(): Observable<any> {

        // console.log('Logging in');

        return this.http.post<any>(environment.apiUrlAuth + '/token', environment.credentials)
            .pipe(map(user => {

                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // localStorage.setItem('user', JSON.stringify(user));
                    this.currentUserSubject.next(user);

                    // localStorage.setItem('currentOrganisation', JSON.stringify(user.permissions[0]));
                }

                return user;
            }));

    }

    isLoggedIn() {
        return this.currentUserValue.length === 0;
    }

    // logout() {
    //     localStorage.removeItem('currentUser');
    //     this.currentUserSubject.next(null);
    // }
}
