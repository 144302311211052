import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { faGameConsoleHandheld } from '@fortawesome/pro-regular-svg-icons';

@Injectable({
  providedIn: 'root'
})
export class StoryService {

  public activeQuestion;

  constructor(
    private http: HttpClient
  ) { }

  getStories(payload): Observable<any> {

    // console.log(payload.filter);

    let params = new HttpParams();
    params = params.append('post_type', 'story');

    if (payload.filter) {

      Object.keys(payload.filter).forEach(key => {
        const value = payload.filter[key];
        if (value) {
          switch (key) {
            case 'term':
              params = params.append('search', value);
              break;
            case 'areas':
              params = params.append('nature-of-work', value);
              break;
            case 'locations':
              params = params.append('location', value);
              break;
            case 'themes':
              params = params.append('theme', value);
              break;
          }
        }
      });
    }

    return this.http.post<any[]>(environment.apiUrl + '/stories-filtered', params );

  }

  get(slug): Observable<any> {
    return this.http.get<any[]>(environment.apiUrl + '/stories/', { params: { slug }} ); // , payload);
  }

  create(story): Observable<any> {

    let params = new HttpParams();
    params = params.append('title', story.title);
    params = params.append('content', story.content);
    params = params.append('image', story.image);
    params = params.append('name', story.name);
    params = params.append('email', story.email);

    params = params.append('nature-of-work', story.area);
    params = params.append('location', story.location);
    params = params.append('theme', story.about);
    params = params.append('question', story.question.term_id);

    return this.http.post<any>(environment.apiUrl + '/create-story', params );
  }

  update(story): Observable<any> {
    return this.http.put<any>(environment.apiUrl + '/stories', story);
  }

  getTerms(type) {
    return this.http.get<any[]>(environment.apiUrl + '/helpers/terms/', { params: { type }});
  }

  // getStories(term): Observable<any> {
  //   console.log(term);
  //   return this.http.post<any>(environment.apiUrl + '/search-stories', { term });
  // }

}
