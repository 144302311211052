import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

import { environment } from '@env/environment';
import { StoryService } from '@services/story/story.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  loading = {
    search: false
  };

  results;
  filter;

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private storyService: StoryService
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Home | ' + environment.siteDescription + ' | ' + environment.siteTitle);
  }

  search(filter) {
    this.loading.search = true;
    this.filter = Object.assign({}, filter);
  }

  loaded(loaded) {
    this.loading.search = false;
  }

}
