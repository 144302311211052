
  <div #imageWrapper id="image-wrapper" class="container-sm p-0">

  <div
    class="wrapper"
    (window:resize)="onResize($event)"
  >
    <webcam
      [width]="width"
      [trigger]="triggerObservable"
      (imageCapture)="handleImage($event)"
      *ngIf="showWebcam && !image"
      [allowCameraSwitch]="allowCameraSwitch"
      [switchCamera]="nextWebcamObservable"
      [videoOptions]="videoOptions"
      [imageQuality]="1"
      (cameraSwitched)="cameraWasSwitched($event)"
      (initError)="handleInitError($event)"
    ></webcam>

    <!-- <button class="btn btn-primary" (click)="triggerSnapshot()">Take Photo</button> -->
  </div>

  <div *ngIf="image">
    <image-cropper
      [imageBase64]="image"
      [maintainAspectRatio]="true"
      [aspectRatio]="1 / 1"
      [roundCropper]="true"
      format="png"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>

    <!-- <button *ngIf="image" class="btn btn-success" (click)="reset()">Use Image</button>
  <button *ngIf="image" class="btn btn-secondary btn-right" (click)="reset()">Re-Take</button> -->
  </div>



  <div
  class="p-2 w-100"

>
  <div class="row">
    <div class="col-6">
      <button
        *ngIf="!image"
        class="btn btn-lg btn-primary"
        (click)="triggerSnapshot()"
        aria-label="Take photo"
      >
        Take Photo
      </button>
      <button
        *ngIf="image"
        class="btn btn-lg btn-success"
        (click)="setImage.emit(croppedImage)"
        aria-label="Use this image"
      >
        Use Image
      </button>
    </div>
    <div class="col-6">
      <button
        *ngIf="!image"
        class="btn btn-lg btn-secondary"
        (click)="cancel.emit()"
        aria-label="Cancel"
      >
        Cancel
      </button>
      <button
        *ngIf="image"
        class="btn btn-lg btn-secondary"
        (click)="reset()"
        aria-label="Re-take the photo"
      >
        Re-Take
      </button>
    </div>
  </div>
</div>

</div>

  

