import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(
    private router: Router
  ) { }

  getUrl() {
  return environment.siteUrl + this.router.url;
  }
}
