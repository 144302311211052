import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationService } from '@auth/authentication.service';
import { JwtInterceptor } from '@auth/jwt.interceptor';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';

import { ToDateObjPipe } from '@pipes/date-object/date-object.pipe';

import { AppComponent } from './app.component';

import { HomeComponent } from '@pages/home/home.component';
import { StoryComponent } from '@pages/story/story.component';
import { ShareComponent } from '@pages/share/share.component';
import { ArchiveComponent } from '@pages/archive/archive.component';
import { ContactComponent } from '@pages/contact/contact.component';

import { HeaderComponent } from '@components/header/header.component';
import { SearchComponent } from '@components/search/search.component';
import { StoriesComponent } from '@components/stories/stories.component';
import { StoryOnboardComponent } from '@components/story-onboard/story-onboard.component';
import { StoryCardComponent } from '@components/story-card/story-card.component';
import { FooterComponent } from '@components/footer/footer.component';
import { PageContentComponent } from '@components/page-content/page-content.component';
import { StoryUserFormComponent } from '@components/story-user-form/story-user-form.component';
import { ImageCaptureComponent } from '@components/image-capture/image-capture.component';
import { StoryContentComponent } from '@components/story-content/story-content.component';
import { PageLoaderComponent } from '@components/page-loader/page-loader.component';

import { NgSelectModule } from '@ng-select/ng-select';
import { WebcamModule } from 'ngx-webcam';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { InlineSVGModule } from 'ng-inline-svg';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import {
  MatProgressSpinnerModule, MatProgressBarModule,
} from '@angular/material';

// Font Awesome
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faSearch, faEye, faPaperPlane, faComment, faFlagAlt, faCheck, faBriefcase } from '@fortawesome/pro-regular-svg-icons';
import { faTimes, faMapMarkerAlt, faCamera, faUpload, faSquare, faCheckSquare, faArrowCircleRight, faArrowCircleLeft, faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { faTwitter, faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    StoryComponent,
    ShareComponent,
    ContactComponent,
    StoryCardComponent,
    FooterComponent,
    PageContentComponent,
    ImageCaptureComponent,
    StoriesComponent,
    StoryOnboardComponent,
    ArchiveComponent,
    StoryUserFormComponent,
    SearchComponent,
    StoryContentComponent,
    PageLoaderComponent,
    ToDateObjPipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    WebcamModule,
    ImageCropperModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    CKEditorModule,
    InlineSVGModule.forRoot(),
    SlickCarouselModule
  ],
  providers: [
    AuthenticationService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    GoogleAnalyticsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faArrowCircleRight,
      faArrowCircleLeft,
      faExclamationCircle,
      faCheck,
      faSquare,
      faCheckSquare,
      faSearch,
      faFlagAlt,
      faMapMarkerAlt,
      faCamera,
      faUpload,
      faEye,
      faTimes,
      faTwitter,
      faFacebookF,
      faInstagram,
      faPaperPlane,
      faBriefcase,
      faComment);
  }
}
