
<app-page-loader *ngIf="!story"></app-page-loader>

<div @fade *ngIf="story">

  <app-header [bg]="color"></app-header>

  <div [ngClass]="color ? 'bg-' + color + ' text-white' : ''">

    <!-- <app-search [color]="color" [categories]="true"></app-search> -->

    <div class="container-md clearfix py-5">

    <mat-progress-spinner *ngIf="!story" [color]="'primary'" [diameter]="60" class="mt-1 ml-2" mode="indeterminate"></mat-progress-spinner>

    <div>

      <div class="row">
        <div class="col-6 col-md-3">
          <img [src]="story.image" class="w-100 rounded-circle mb-4 mb-md-5 box-shadow" [attr.alt]="'Picture of ' + story.post_title"/>
        </div>
        <div class="col-12 col-md-9">

          <div class="_pl-0 _pl-md-0">

            <div class="date mb-3">
              <strong>
                <span class="day">{{ story.date | date:'dd' }}</span><br />
                <span class="month">{{ story.date | date:'LLL' }}</span>
              </strong>
            </div>
      
            <div class="meta position-relative mb-3" style="left:0"><ng-container *ngFor="let category of story.categories; let last = last;">
              {{ category.name }}<span *ngIf="!last"> | </span></ng-container>
            </div>
            <h2 class="mb-5">{{ story.excerpt }}</h2>
            <div [innerHtml]="story.content.rendered"></div>

            <strong [innerHtml]="story.title.rendered"></strong>

            <div class="social-icons mb-4 mb-md-5">
              <a href="http://www.twitter.com/share?url={{ getPageURL() }}" target="_blank" aria-label="Share this story on Twitter"><fa-icon [icon]="['fab', 'twitter']" class="mr-4" [class.text-white]="color"></fa-icon></a>
              <a href="https://www.facebook.com/sharer/sharer.php?u={{ getPageURL() }}" target="_blank" aria-label="Share this story on Facebook"><fa-icon [icon]="['fab', 'facebook-f']" class="mr-4" [class.text-white]="color"></fa-icon></a>
              <a href="https://www.instagram.com/?url={{ getPageURL() }}" target="_blank" aria-label="Share this story on Instagram"><fa-icon [icon]="['fab', 'instagram']" class="mr-4" [class.text-white]="color"></fa-icon></a>
              <!-- <a href=""><fa-icon [icon]="['far', 'paper-plane']" class="mr-4" [class.text-white]="color"></fa-icon></a> -->
            </div>

          </div>

        </div>
      </div>

    </div>

  </div>

  </div>

  <!-- <div class="container-xl py-5">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="rounded pt-5 pb-5 px-5 bg-{{ color }} text-white clearfix">
          <div class="meta pb-3">Leadership</div>
          <img src="https://picsum.photos/80" class="float-left rounded-circle mr-4" />
          <span>"The first time my patient died, I felt the loss deeply. Everyone told me to toughen … up, and grow a thicker skin."</span>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="rounded pt-5 pb-5 px-5 bg-{{ color }} text-white clearfix">
          <div class="meta pb-3">Leadership</div>
          <img src="https://picsum.photos/80" class="float-left rounded-circle mr-4" />
          <span>"The first time my patient died, I felt the loss deeply. Everyone told me to toughen … up, and grow a thicker skin."</span>
        </div>
      </div>
    </div>
  </div> -->

  <div class="text-center">
    <a class="cursor text-decoration-none my-4 my-md-5 btn btn-link" routerLink="/stories" aria-label="Go back to all stories">
      <fa-icon class="h1" [icon]="['fal', 'arrow-circle-left']"></fa-icon><br />
      <span class="text-uppercase">Return to all stories</span>
    </a>
  </div>

  <app-footer></app-footer>

</div>