// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: 'https://admin.leadingtochange.scot',
  apiUrl: 'https://admin.leadingtochange.scot/wp-json/wp/v2',
  apiUrlAuth: 'https://admin.leadingtochange.scot/wp-json/jwt-auth/v1',
  siteUrl: 'http://localhost:4200',
  siteTitle: 'Stories That Inspire Change',
  siteDescription: 'A trusted space for people working anywhere in or with Health and Social Care to be themselves',
  credentials: {
    username: 'storiesthatinspire',
    password: 'eBfc^V5jGusHSO&)hF(HTnV3'
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
