<footer class="bg-beige-dark mt-sm-5">
  <div class="container-md text-center text-md-left">
    <div class="icon-container">
      <!-- <a href="//leadingtochange.scot" target="_blank">
        <img src="assets/images/ltc-logo-purple.svg" class="mr-3" alt="Project Lift purple logo" />
      </a> -->
      <a href="//leadingtochange.scot/about" target="_blank" aria-label="Find out who's behind this"><span>Brought
          to you by Leading to Change</span></a>
    </div>
    <ul class="mt-4 mt-md-0 mb-0 p-0">
      <li><a routerLink="/about" aria-label="About Stories That Inspire Change">About</a></li>
      <li><a routerLink="/contact" aria-label="How you can contact us">Contact</a></li>
      <li><a routerLink="/privacy" aria-label="Read our privacy policy">Privacy</a></li>
      <li><a routerLink="/terms-and-conditions" aria-label="Our terms and conditions">Terms & Conditions</a></li>
    </ul>
  </div>
</footer>