import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@auth/authentication.service';
import { MailService } from '@services/mail/mail.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Title, Meta } from '@angular/platform-browser';

import { environment } from '@env/environment';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  submitted = false;
  activated = false;
  output;

  form: FormGroup;

  loading = false;
  returnUrl: string;
  error = '';
  complete = false;

  details = {
    firstname: '',
    lastname: '',
    email: '',
    message: ''
  };

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private mailService: MailService,
    private titleService: Title,
    private metaService: Meta
  ) { }

  ngOnInit() {

    this.titleService.setTitle('Contact | ' + environment.siteDescription + ' | ' + environment.siteTitle);

    this.form = this.formBuilder.group({
      firstname: [this.details.firstname, Validators.required],
      lastname: [this.details.lastname],
      email: [this.details.email, Validators.required],
      message: [this.details.message, Validators.required],
    });

  }

  submit(form) {

    this.loading = true;

    if (this.authenticationService.currentUserValue.token) {

      this.send(form);

    } else {

      this.authenticationService.login()
      .subscribe(
          data => {

            this.send(form);

          },
          error => {
              this.error = error.error;
              this.loading = false;
          }
        );

    }

  }

  send(payload) {

    this.mailService.send(payload).subscribe(
      res => {
        // console.log(res);

        if (res) {
          this.complete = true;
        }

        this.loading = false;
      }
    );

  }

}
