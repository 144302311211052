import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import {trigger, style, animate, transition} from '@angular/animations';

import { environment } from '@env/environment';

import { StoryService } from '@app/services/story/story.service';
import { HelperService } from '@app/services/helper/helper.service';

@Component({
  selector: 'app-story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(200, style({opacity: 1}))
      ])
    ])
  ]
})
export class StoryComponent implements OnInit {

  slug;
  story;

  color;
  colors = ['purple', 'mustard', 'teal', 'blue', 'lime', 'pink'];

  constructor(
    private route: ActivatedRoute,
    private storiesService: StoryService,
    private titleService: Title,
    private metaService: Meta,
    private helperService: HelperService,
  ) {

   }

  ngOnInit() {

    this.route.params.subscribe(params => {
      this.storiesService.get(params.slug).subscribe(
        story => {

          this.story = story[0];

          if (this.story.categories[0]) {
            this.color = this.story.categories[0].color;
          } else {
            this.color = this.colors[Math.floor(Math.random() * this.colors.length)];
          }

          this.titleService.setTitle(this.story.title.rendered + ' | ' + environment.siteDescription + ' | ' + environment.siteTitle);

        }
      );
    });
  }

  getPageURL() {
    return this.helperService.getUrl();
  }

}
