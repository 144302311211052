
      <div *ngIf="submitted && form.invalid" class="alert alert-primary" role="alert">
        Please make sure you've completed all required fields
      </div>
      <form name="form" #f="ngForm" class="form text-left">

        <div class="form-group">
          <input required name="name" class="form-control" placeholder="My name is"
          [(ngModel)]="story.name"
          [class.is-invalid]="form.controls['name'].touched && form.controls['name'].errors"
          [class.is-valid]="form.controls['name'].touched && !form.controls['name'].errors" />
        </div>

        <div class="form-group">
          <input required name="email" class="form-control" placeholder="My email address is"
          [(ngModel)]="story.email"
          [class.is-invalid]="form.controls['email'].touched && form.controls['email'].errors"
          [class.is-valid]="form.controls['email'].touched && !form.controls['email'].errors" />
        </div>

        <div class="form-group">
          <ng-select
            required
            (ngModelChange)="autosave.emit()"
            [(ngModel)]="story.about"
            name="about"
            [items]="topics"
            [multiple]="true"
            bindValue="term_id"
            bindLabel="name"
            [searchable]="false"
            [placeholder]="'My story is about'"
            [class.is-invalid]="form.controls['about'].touched && form.controls['about'].errors">
          </ng-select>
        </div>

        <div class="form-group">
          <ng-select
            required
            (ngModelChange)="autosave.emit()"
            [(ngModel)]="story.location"
            name="location"
            [items]="locations"
            [multiple]="true"
            bindValue="term_id"
            bindLabel="name"
            [searchable]="false"
            [placeholder]="'My location of work is'"
            [class.is-invalid]="form.controls['location'].touched && form.controls['location'].errors">
          </ng-select>
        </div>

        <div class="form-group">
          <ng-select
            required
            (ngModelChange)="autosave.emit()"
            [(ngModel)]="story.area"
            name="area"
            [items]="areas"
            [multiple]="true"
            bindValue="term_id"
            bindLabel="name"
            [searchable]="false"
            [placeholder]="'My area of work is'"
            [class.is-invalid]="form.controls['area'].touched && form.controls['area'].errors">
          </ng-select>
        </div>

        <div class="navigation clearfix">
          <button class="btn btn-link float-left" (click)="back.emit()">
            <fa-icon [icon]="['fal', 'arrow-circle-left']"></fa-icon>
          </button>
          <button class="btn btn-link float-right" [disabled]="!f.form.valid" (click)="continue.emit()">
            <fa-icon [icon]="['fal', 'arrow-circle-right']"></fa-icon>
          </button>
        </div>
        
      </form>
